import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I'm a back-end software engineer who's passionate about domain modeling and writing documentation.
Growing up, I wanted to be a scientist like my Dad. In college, I pursued
a dual major in Biochemistry and Computer Science so that I could work
with data over their entire lifecycle.`}</p>
    <p>{`When I graduated college, I jumped into Harvard's Biological and
Biomedical Science Ph.D. program, where I conducted research on axolotl
limb regeneration. While I loved working on such an amazing topic, I
decided to pursue a career in software and joined Klaviyo, an email marketing startup, in the fall of 2018.`}</p>
    <p>{`My fiancé and I moved to Seattle in the summer of 2019, and I began
my journey to become a full stack master at The Pokémon Company International.`}</p>
    <p>{`Outside of work, I crochet, play ultimate frisbee, ski, and hike.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      